<template>
    <footer
        id="footer-landmark"
        class="the-footer"
        v-bind="$attrs"
    >
        <div class="the-footer__content">
            <section class="the-footer__intro">
                <p v-if="data?.footer?.description">
                    {{ data.footer.description }}
                </p>

                <CallToAction parent-component="TheFooter">
                    <template #cta>
                        {{ $t('book-your-stay') }}
                    </template>
                </CallToAction>

                <template v-if="data?.contactInfo?.email">
                    <br>
                    <NuxtLink :to="`mailto:${data.contactInfo.email}`">{{ data.contactInfo.email }}</NuxtLink>
                </template>

                <template v-if="data?.contactInfo?.phoneNumber">
                    <br>
                    <NuxtLink :to="`tel:${data.contactInfo.phoneNumber}`">{{ data.contactInfo.phoneNumber }}</NuxtLink>
                </template>
            </section>

            <nav class="the-footer__menu">
                <menu>
                    <li
                        v-for="item in menu"
                        :key="item.id"
                    >
                        <BaseDotLink :active="false">
                            <CraftLink :link="item.link" />
                        </BaseDotLink>
                    </li>
                </menu>
            </nav>

            <section class="the-footer__awards the-footer__logos">
                <nuxt-link
                    to="https://greenglobe.com"
                    target="_blank"
                >
                    <nuxt-picture
                        src="/img/logos/greenglobe.png"
                        alt="Green Globe Certified"
                        width="72"
                        height="72"
                        fit="inside"
                        :img-attrs="{ fetchpriority: 'low', loading: 'lazy' }"
                    />
                </nuxt-link>
                <nuxt-link
                    to="https://greatplacetowork.com"
                    target="_blank"
                >
                    <nuxt-picture
                        src="/img/logos/great-place-to-work.png"
                        alt="Great Place to Work Award"
                        width="42"
                        height="72"
                        fit="inside"
                        :img-attrs="{ fetchpriority: 'low', loading: 'lazy' }"
                    />
                </nuxt-link>
            </section>

            <section class="the-footer__partners">
                <label>{{ $t('Partners') }}</label>
                <div class="the-footer__logos">
                    <nuxt-link
                        to="https://www.amsterdamidaparthotel.com/"
                        target="_blank"
                    >
                        <nuxt-picture
                            src="/img/logos/idaparthotel.svg"
                            alt="ID APARTHOTEL"
                            width="72"
                            fit="inside"
                            :img-attrs="{ fetchpriority: 'low', loading: 'lazy' }"
                        />
                    </nuxt-link>
                    <nuxt-link
                        to="https://www.eura-relocation.com/"
                        target="_blank"
                    >
                        <nuxt-picture
                            src="/img/logos/eura.svg"
                            alt="EuRA"
                            width="72"
                            fit="inside"
                            :img-attrs="{ fetchpriority: 'low', loading: 'lazy' }"
                        />
                    </nuxt-link>
                </div>
            </section>

            <section class="the-footer__legal">
                <span>&copy; The July {{ (new Date()).getFullYear() }}</span>
                <NuxtLinkLocale to="/terms">{{ $t('terms-and-conditions') }}</NuxtLinkLocale>
                <NuxtLinkLocale to="/privacy">{{ $t('privacy-policy') }}</NuxtLinkLocale>
            </section>
        </div>
    </footer>
</template>

<script setup>

import FooterQuery from '~/graphql/queries/footer.graphql';
const menu = ref([]);
const { variables } = useCraftGraphql();
// Ignore the URI in this case
delete variables.uri;
const { data } = await useLazyAsyncQuery({ query: FooterQuery , variables });
watchEffect(() => {
    menu.value = toValue(data)?.footer?.menu;
});
</script>

<style lang="less" src="./TheFooter.less" />
