<template>
    <BaseDropdown
        v-if="!pending"
        class="language-switcher"
        :items="availableLocales.map(l => l.language.code)"
        :active-item="activeLocale"
        data-direction="up"
    >
        <template #trigger>
            <BaseDotLink>
                {{ $t(`language.${activeLocale}`) }}
            </BaseDotLink>
        </template>

        <template
            v-for="item in availableLocales"
            #[`item-${item.language.code}`]
            :key="item"
        >
            <nuxt-link
                class="language-switcher__link"
                :to="item.uri"
                @click="isExpanded = false"
            >
                <BaseDotLink :active="activeLocale === item.language.code">
                    {{ $t(`language.${item.language.code}`) }}
                </BaseDotLink>
            </nuxt-link>
        </template>
    </BaseDropdown>
</template>

<script setup>
import { HOME_URI } from '~/enums/page-uri';

const props = defineProps(
    {
        uri: {
            type: String,
            default: null,
            required: false
        },
        hideUnavailable: {
            type: Boolean,
            default: false
        }
    }
);

const isExpanded = ref(false);

/**
 * Logic for fetching the alternate URI's for the current page
 */
const gqlQuery = `query localizedUri($site: [String!]!, $uri: [String!]) {
    entry(site: $site, uri: $uri) {
        uri
        language
        localized {
            uri
            language
        }
    }
}`;
const localePath = useLocalePath();
const switchLocalePath = useSwitchLocalePath();
const { locales, locale: activeLocale } = useI18n();

const { variables } = useCraftGraphql();
const { pending, data } = await useLazyAsyncQuery({
    query: gqlQuery,
    variables
});

const availableLocales = computed(() => {
    const items = [];
    // Loop through the configured locales in nuxt.config.js
    locales.value.forEach((language) => {
        const unreffedData = unref(data);
        // If this is the active locale, return the current URI
        if (unreffedData && language.code === activeLocale.value) {
            items.push({
                language: toValue(language),
                isFallback: false,
                uri: unreffedData.uri
            });
        }
        // No data means this is likely not a craft entry, so return the locally stored URI
        else if (unreffedData && !unreffedData.entry) {
            items.push({
                language: toValue(language),
                isFallback: false,
                uri: switchLocalePath(language.code)
            });
        } else if (unreffedData?.entry?.localized?.length) {
            // Attempt to map language codes between nuxt and craft
            const craftCode = language.craftLanguage || language.iso || language.code;
            const localizedEntry = unreffedData.entry.localized.filter(l => l.language === craftCode)[0];
            if (localizedEntry) {
                if (localizedEntry.uri === HOME_URI) {
                    localizedEntry.uri = '';
                }
                // Entry is available in this language
                items.push({
                    language: toValue(language),
                    isFallback: false,
                    uri: localePath('/' + localizedEntry.uri, language.code)
                });
            } else if (!props.hideUnavailable) {
                // If entry is not available in this language, point to the home page
                items.push({
                    language: toValue(language),
                    isFallback: true,
                    uri: localePath('/', language.code)
                });
            }
        }
    });
    return items;
});
</script>

<style lang="less" src="./TheLanguageSwitcher.less" />
