<template>
    <div class="call-to-action-button">
        <BaseButton @click="openBookingOverlay({ location: null,parentComponent })">
            <i18n-t
                v-if="!propertyPage"
                tag="span"
                keypath="call-to-action.text"
                scope="global"
            >
                <template #long>
                    <span class="hidden-xxs  hidden-xs hidden-sm hidden-md">
                        {{ $t('call-to-action.long-text') }}
                    </span>
                </template>
            </i18n-t>
            <template v-else>
                <span class="hidden-lg hidden-xl hidden-xxl">
                    {{ $t('call-to-action.text') }}
                </span>
                <span class="hidden-xxs hidden-xs hidden-sm hidden-md">
                    {{ $t('call-to-action.check-availability') }}
                </span>
            </template>

            <BaseIcon
                :class="{
                    'hidden-xxs hidden-xs hidden-sm': hideIconOnMobile
                }"
                icon="calendar"
            />
        </BaseButton>

        <slot name="text" />
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useBookingOverlayStore } from '@/store/booking-overlay';
const { openBookingOverlay } = useBookingOverlayStore();

const route = useRoute();
const propertyPage = ref(false);

watch(
    () => route.path,
    () => {
        if (route.path.includes('/properties/')) {
            propertyPage.value = true;
        } else {
            propertyPage.value = false;
        }
    },
    {
        immediate: true
    }
);

defineProps({
    hideIconOnMobile: {
        type: Boolean,
        default: false,
        required: false
    },
    parentComponent: {
        type: String,
        default: null
    }
});
</script>

<style lang="less">
@import './CallToActionButton.less';
</style>
