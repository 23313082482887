<template>
    <div class="hamburger-menu">
        <button
            :class="{ 'open': mainMenuOpen }"
            type="button"
            class="hamburger-menu__button button--transparent button--icon-only"
            aria-label="Open menu"
            @click="navigationStore.toggleMainMenu()"
        >
            <BaseDotLink>
                {{ mainMenuOpen ? $t('menu.actions.close') : $t('menu.actions.open') }}
            </BaseDotLink>
        </button>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useNavigationStore } from '~/store/navigation';

const navigationStore = useNavigationStore();
const { mainMenuOpen } = storeToRefs(navigationStore);
</script>

<style lang="less" src="./TheHamburgerMenu.less"></style>
