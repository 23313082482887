<template>
    <DatePicker
        v-model.range="range"
        mode="date"
        :disabled-dates="disabledDates"
        borderless
        transparent
        expanded
        :initial-page="currentPage"
        :locale="locale"
        :color="'gray'"
    >
        <template #footer>
            <slot name="footer" />
        </template>
    </DatePicker>
</template>

<script setup>
import { sub, getMonth, getYear } from 'date-fns';
import 'v-calendar/style.css';

const { DatePicker } = await import('v-calendar');
const { locale } = useI18n();

const currentPage = computed(() => {
    return {
        month: getMonth(new Date()) + 1,
        year: getYear(new Date())
    };
});

const emit = defineEmits(['update:date']);

defineProps({
    date: {
        type: Object,
        default: () => {},
        required: false
    }
});

const range = ref({
    start: null,
    end: null
});

watch(range, () => {
    emit('update:date', {
        start: range.value.start,
        end: range.value.end
    });
});

// Disable dates before today
const disabledDates = ref([{
    start: null,
    end: sub(new Date(), { days: 1 })
}]);
</script>

<style lang="less">
.vc-header {
    margin-top: 0;
}

.vc-header .vc-title,
.vc-header .vc-arrow {
    background: none;
}

.vc-highlight-content-outline,
.vc-highlight-content-light,
.vc-highlight-content-solid {
    // stylelint-disable-next-line declaration-no-important
    font-weight: 400 !important;
}

.vc-focus {
    mix-blend-mode: multiply;
}

.vc-light .vc-gray,
.vc-gray {
    --vc-content-color: var(--color-darkest);
    --vc-highlight-outline-content-color: var(--color-darkest);
    --vc-highlight-light-content-color: var(--color-darkest);
    --vc-highlight-solid-content-color: var(--color-darkest);

    --vc-accent-50: var(--color-cream);
    --vc-accent-100: var(--color-cream);
    --vc-accent-200: var(--color-cream);
    --vc-accent-500: var(--color-cream);
    --vc-accent-600: #c6beb4;
    --vc-accent-700: var(--color-cream);
    --vc-accent-800: var(--color-cream);
}
</style>
