<template>
    <form
        class="the-booking-overlay-step"
        @submit.prevent="handleSubmit"
    >
        <div class="the-booking-overlay-step__body">
            <slot name="default" />
        </div>

        <BaseButton
            class="base-button--secondary base-button--wide"
            :disabled="!canContinue"
        >
            <slot name="button" />
            <BaseIcon icon="arrow" />
        </BaseButton>
    </form>
</template>

<script setup>
const emit = defineEmits([
    'update:step',
    'finish'
]);

const props = defineProps({
    step: {
        type: Number,
        default: 1,
        required: false
    },

    canContinue: {
        type: Boolean,
        default: true
    }
});

function handleSubmit() {
    if (!props.canContinue) {
        return;
    }

    if (props.step < 3) {
        emit('update:step', props.step + 1);
    } else {
        emit('finish');
    }
}
</script>

<style lang="less">
.the-booking-overlay-step {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
}

.the-booking-overlay-step__body {
    max-height: 50svh;
    overflow-y: auto;

    @media @q-md-min {
        max-height: 50svh;
    }
}
</style>
