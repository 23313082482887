<template>
    <div class="base-counter">
        <BaseButton
            element="a"
            class="base-counter__button base-button--round"
            :class="{
                'base-counter__button--disabled': !canDecrease,
            }"
            @click="canDecrease ? $emit('update:modelValue', modelValue - 1) : null"
        >
            <span class="sr-only">Decrease</span>
            <BaseIcon icon="minus" />
        </BaseButton>
        {{ modelValue }}
        <BaseButton
            element="a"
            class="base-counter__button base-button--round"
            :class="{
                'base-counter__button--disabled': !canIncrease,
            }"
            @click="canIncrease ? $emit('update:modelValue', modelValue + 1) : null"
        >
            <span class="sr-only">Increase</span> 
            <BaseIcon icon="plus" />
        </BaseButton>
    </div>
</template>

<script setup>
const props = defineProps({
    modelValue: {
        type: Number,
        default: 0
    },
    min: {
        type: Number,
        default: null
    },
    max: {
        type: Number,
        default: null
    }
});
const canIncrease = computed(() => {
    return props.max !== null ? props.modelValue < props.max : true;
});

const canDecrease = computed(() => {
    return props.min !== null ? props.modelValue > props.min : true;
});
defineEmits(['update:modelValue']);
</script>

<style lang="less">
.base-counter {
    display: inline-flex;
    align-items: center;
    gap: .5rem;
}

.base-counter__button.base-button {
    background-color: var(--color-cream);
    margin: 0;
    height: 2rem;
    width: 2rem;
}

.base-counter__button--disabled {
    opacity: .25;
    pointer-events: none;
}
</style>