<template>
    <!--
        Skiplinks link to landmarks in the website.
        Don't forget to add the IDs below to your custom elements.
        Keep in mind there can only be 1 <main> and 1 <footer> component in the context of the body
    -->
    <div class="skip-links">
        <BaseButton
            class="skip-links__link"
            element="a"
            href="#main-content-landmark"
        >
            Jump to main content
        </BaseButton>
        <BaseButton
            class="skip-links__link"
            element="a"
            href="#footer-landmark"
        >
            Jump to footer
        </BaseButton>
    </div>
</template>

<style lang="less" src="./SkipLinks.less"></style>
